.markdown-content {
  width: 80%;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
  overflow: auto;
}
.markdown-content {
  position: relative;
}