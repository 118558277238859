.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

button {
  margin: 10px auto;
  background-color: orangered;
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 200px;
  font-weight: bold;
}

button:hover {
  opacity: 0.8;
}

#home-button {
  position: fixed;
  top: 0;
  left: 0;
  margin: 7px;
  background-color: orangered;
  color: black;
  font-weight: bold;
}

#menu-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgb(157, 163, 163);
  height: 50px;
}